<template>
	<ZyroModal
		:title="$t('common.blogSettings')"
		width="360px"
		height="auto"
		:show-close-button="false"
		@close-modal="closeModal"
	>
		<ZyroTabs
			:tabs="$options.tabs"
			:current-tab="currentTab"
			@change="currentTab = $event"
		/>
		<Component :is="currentTab.component" />
	</ZyroModal>
</template>

<script>
import BlogSettingsTabCategories from '@/components/builder-controls/blog-drawer/BlogSettingsTabCategories.vue';
import i18n from '@/i18n/setup';
import {
	mapActionsGui,
	CLOSE_MODAL,
} from '@/store/builder/gui';

const tabs = [
	{
		id: 'categories',
		title: i18n.t('common.categories'),
		component: 'BlogSettingsTabCategories',
	},
];

export default {
	components: { BlogSettingsTabCategories },
	data() {
		return { currentTab: this.$options.tabs[0] };
	},
	methods: mapActionsGui({ closeModal: CLOSE_MODAL }),
	tabs,
};
</script>
