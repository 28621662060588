<template>
	<ZyroPopup
		class="popup"
		:show-close-button="false"
		type="no-padding"
		:offset="popupOffset"
		:on-toggle="handlePopupToggle"
	>
		<template #trigger>
			<ZyroButton
				icon="settings"
				:title="$t('common.settings')"
				data-qa="edit-items-list-trigger"
			/>
		</template>
		<template #default="popup">
			<ul class="popup-content">
				<li
					v-for="{
						id, icon, title, text
					} in $options.BLOG_CATEGORY_POPUP_BUTTONS"
					:key="`${id}-button`"
				>
					<ZyroButton
						v-qa="`edit-items-list-popup-button-${id}`"
						theme="plain"
						:icon-left="icon"
						:title="title"
						class="popup-content__button"
						@click="handleClick(popup, id)"
					>
						{{ text }}
					</ZyroButton>
				</li>
			</ul>
		</template>
	</ZyroPopup>
</template>

<script>
import { BLOG_CATEGORY_POPUP_BUTTONS } from './constants';

const POPUP_POSITION_TOP = {
	top: 20,
	right: 13,
};
const POPUP_POSITION_BOTTOM = {
	bottom: 0,
	right: 16,
};

export default {
	props: {
		onPopupOpen: {
			type: Function,
			default: () => null,
		},
		popupOpensToBottom: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		popupOffset() {
			return this.popupOpensToBottom ? POPUP_POSITION_TOP : POPUP_POSITION_BOTTOM;
		},
	},
	methods: {
		handleClick(popup, buttonId) {
			popup.toggle();
			this.$emit(buttonId);
		},
		handlePopupToggle(isOpen, triggerPosition) {
			if (isOpen) {
				this.onPopupOpen(triggerPosition);
			}
		},
	},
	BLOG_CATEGORY_POPUP_BUTTONS,
};
</script>

<style lang="scss" scoped>
.popup-content {
	overflow: hidden;
	list-style: none;

	&__button {
		font-size: 14px;
		font-weight: normal;
		line-height: 1.43;
		text-transform: none;
		letter-spacing: 0.01em;
	}
}
</style>
