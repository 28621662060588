import i18n from '@/i18n/setup';

export const BLOG_CATEGORY_POPUP_BUTTONS = [
	{
		id: 'edit',
		icon: 'edit',
		title: i18n.t('common.edit'),
		text: i18n.t('common.edit'),
	},
	{
		id: 'duplicate',
		icon: 'duplicate',
		title: i18n.t('common.duplicate'),
		text: i18n.t('common.duplicate'),
	},
	{
		id: 'delete',
		icon: 'trash',
		title: i18n.t('common.delete'),
		text: i18n.t('common.delete'),
	},
];
