<template>
	<EditableItemsList
		ref="items"
		:items="editableItems"
		:placeholder="$t('builder.blog.blogPostSettings.writeCategories')"
		:validate-value="validator"
		@edit="editItem"
		@update-items="updateItems"
	>
		<template slot="header">
			<EditableItemsAddButton
				:button-text="$t('builder.blog.blogPostSettings.addCategory')"
				:placeholder="$t('builder.blog.blogPostSettings.writeCategories')"
				:validate-value="validator"
				@add="addItem"
			/>
		</template>
		<template
			#item-button="{ item, startEditingItem }"
		>
			<BlogCategoryPopup
				:on-popup-open="handlePopupOpen"
				:popup-opens-to-bottom="popupOpensToBottom"
				@duplicate="duplicateItem(item.name)"
				@delete="removeCategory(item.name)"
				@edit="startEditingItem"
			/>
		</template>
	</EditableItemsList>
</template>

<script>
import {
	mapActions,
	mapGetters,
} from 'vuex';

import EditableItemsAddButton from '@/components/reusable-components/editable-items-list/-partials/EditableItemsAddButton.vue';
import EditableItemsList from '@/components/reusable-components/editable-items-list/EditableItemsList.vue';

import BlogCategoryPopup from './BlogCategoryPopup.vue';

export default {
	components: {
		EditableItemsList,
		EditableItemsAddButton,
		BlogCategoryPopup,
	},
	data() {
		return { popupOpensToBottom: false };
	},
	computed: {
		...mapGetters('blog', [
			'categoriesNames',
			'categoryIdByName',
		]),
		editableItems() {
			return this.categoriesNames.map((name) => ({ name }));
		},
	},
	methods: {
		...mapActions('blog', [
			'editCategory',
			'addCategory',
			'duplicateCategory',
			'removeCategory',
			'setCategories',
		]),
		validator(categoryName) {
			if (!categoryName) {
				return {
					isValid: false,
					error: this.$t('validate.emptyValue'),
				};
			}

			if (this.categoriesNames.includes(categoryName)) {
				return {
					isValid: false,
					error: this.$t('builder.blog.blogPostSettings.error'),
				};
			}

			return {
				isValid: true,
				error: '',
			};
		},
		updateItems(newCategoryNames) {
			const newCategories = newCategoryNames.reduce(
				(newCategoriesArray, { name }) => ({
					...newCategoriesArray,
					[this.categoryIdByName(name)]: { name },
				}),
				{},
			);

			this.setCategories({ categories: newCategories });
		},
		editItem({
			oldValue,
			newValue,
		}) {
			this.editCategory({
				oldValue: oldValue.name,
				newValue: newValue.name,
			});
		},
		addItem(newValue) {
			this.addCategory({ name: newValue });
		},
		duplicateItem(newValue) {
			this.duplicateCategory({ name: newValue });
		},
		handlePopupOpen(popupTriggerPosition) {
			const {
				height: scrollAreaHeight,
				top: scrollAreaTop,
			} = this.$refs.items.$refs.items.getBoundingClientRect();
			const popupTopPositionInScrollArea = popupTriggerPosition.top - scrollAreaTop;
			/*
			 * By default, popup should be opened on the bottom of the item,
			 * unless it's not enough space for popup to fit.
			 * Example:
			 * Open popup of the last item in scroll and it won't be visible, you'd have to scroll down
			 *
			 * Edge case: if user screen has an unnatural height screen open the menu to the bottom.
			 */
			const RESERVED_SPACE_FOR_POPUP = 250;

			this.popupOpensToBottom = scrollAreaHeight < RESERVED_SPACE_FOR_POPUP
				|| scrollAreaHeight > popupTopPositionInScrollArea + RESERVED_SPACE_FOR_POPUP;
		},
	},
};
</script>
